import { get_date_components } from 'components/modules/date';

export const RECIPROCITY = {
		id: -1,
		user_id: '',
		term_year: '',
		term_name: '',
		status: '',
		submit_date: '0000-00-00 00:00:00',
		dob: '',
		gender: '',
		ssn: '',
		us_citizen: '',
		provide_parent_info: '',
		addr_street: '',
		addr_city: '',
		addr_state: '',
		addr_zip: '',
		phone: '',
		hs_name: '',
		hs_city: '',
		hs_state: '',
		hs_grad_date: '',
		level_of_study: '',

		relationship: '',
		relationship_addr_street: '',
		relationship_addr_city: '',
		relationship_addr_state: '',
		relationship_addr_zip: '',
		relationship_us_citizen: '',
		relationship_visa_type: '',
		relationship_visa_type_other: '',
		relationship_lived_wi_12_months: '',
		relationship_employed: '',
		relationship_income_taxes: '',
		relationship_registered_vote: '',
		relationship_valid_license: '',
		self_lived_wi_12_months: '',
		self_live_in_wi: '',
		self_lived_wi_date: '',
		self_employed: '',
		self_income_taxes: '',
		self_registered_vote: '',
		self_valid_license: '',
		self_claim_dependent: '',
		self_he_enrolled: '',

		campuses: [],
		higher_ed: []
	},
	HIGHER_ED = {
		school_name: '',
		attended_from: '',
		attended_to: ''
	},
	adult_age = 25,
	colleges = {
		uw: [
			'UW-Eau Claire',
			'UW-Green Bay',
			'UW-La Crosse',
			'UW-Madison',
			'UW-Milwaukee',
			'UW-Oshkosh',
			'UW-Parkside',
			'UW-Platteville',
			'UW-River Falls',
			'UW-Stevens Point',
			'UW-Stout',
			'UW-Superior',
			'UW-Whitewater'
		],
		uw_colleges: [
			'UW-Eau Claire - Barron County',
			'UW-Green Bay - Manitowoc Campus',
			'UW-Green Bay - Marinette Campus',
			'UW-Green Bay - Sheboygan Campus',
			'UW-Milwaukee at Washington County',
			'UW-Milwaukee at Waukesha',
			'UW-Oshkosh, Fond du Lac Campus',
			'UW-Oshkosh, Fox Cities Campus',
			'UW-Platteville Baraboo Sauk County',
			'UW-Platteville Richland',
			'UW-Stevens Point at Marshfield',
			'UW-Stevens Point at Wausau',
			'UW-Whitewater at Rock County'
		],
		mn: [
			'University of Minnesota - Crookston',
			'University of Minnesota - Duluth',
			'University of Minnesota - Morris',
			'University of Minnesota - Rochester',
			'University of Minnesota - Twin Cities'
		],
		mn_state: [
			'Bemidji State University',
			'Metropolitan State University',
			'Minnesota State University - Mankato',
			'Minnesota State University - Moorhead',
			'Saint Cloud State University',
			'Southwest Minnesota State University',
			'Winona State University'
		],
		mn_state_college: [
			'Anoka Ramsey Community College',
			'Central Lakes Community & Technical College',
			'Century Community & Technical College',
			'Fond du Lac Community College',
			'Hibbing Community & Technical College',
			'Inver Hills Community College',
			'Itasca Community College',
			'Lake Superior Community & Technical College',
			'Mesabi Community & Technical College',
			'Minneapolis Community & Technical College',
			'Minnesota State Community & Technical College',
			'Minnesota West Community & Technical College',
			'Normandale Community College',
			'North Hennepin Community College',
			'Northland Community & Technical College',
			'Rainy River Community College',
			'Ridgewater Community & Technical College',
			'Riverland Community & Technical College',
			'Rochester Community & Technical College',
			'Vermilion Community College'
		]
	},
	getAvailableTerms = () => {
		let d = get_date_components(),
			opts = [];

		if (d.month < 6) opts.push(`Spring ${d.year}`);
		if (d.month < 7) opts.push(`Summer ${d.year}`);

		if (d.month >= 2) {
			opts.push(`Fall ${d.year}`);
			opts.push(`Spring ${d.year + 1}`);
			opts.push(`Summer ${d.year + 1}`);
		}

		return opts;
	};
